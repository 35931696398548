<template>
    <div class="d-flex flex-column flex-root">
        <div
            class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        >
            <!--begin::Aside-->
            <div
                class="login-aside d-flex flex-column flex-row-auto"
                style="background-color: #F2C98A;"
            >
                <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
                    <a href="#" class="text-center mb-10">
                        <img
                            src="media/logos/logo-letter-1.png"
                            class="max-h-70px"
                            alt=""
                        />
                    </a>
                    <!--          <h3-->
                    <!--            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"-->
                    <!--            style="color: #986923;"-->
                    <!--          >-->
                    <!--            Discover Amazing Metronic <br />with great build tools-->
                    <!--          </h3>-->
                </div>
                <div
                    class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
                    :style="{ backgroundImage: `url(${backgroundImage})` }"
                ></div>
            </div>
            <!--begin::Aside-->
            <!--begin::Content-->
            <div
                id="kt_content"
                class="content d-flex flex-column flex-column-fluid"
            >
                <!--<div
                  id="kt_content"
                  class="content d-flex flex-column flex-column-fluid"
                > -->
                <!-- begin:: Content Head -->

                <!-- begin:: Content Head -->
                <!-- end:: Content Head -->

                <!-- begin:: Content Body -->
                <div class="d-flex flex-column-fluid">
                    <div
                        :class="{
                'container-fluid': !contentFluid,
                container: contentFluid
              }"
                    >
                        <transition name="fade-in-up">
                            <router-view />
                        </transition>
                    </div>
                </div>
            </div>
            <!--end::Content-->
        </div>
    </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
    name: "LayoutAuth",
    data() {
        return {
        };
    },
    computed: {
        ...mapState({
            errors: state => state.auth.errors
        }),
        ...mapGetters(["currentUser", "layoutConfig"]),

        backgroundImage() {
            return (
                process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
            );
        },
        contentFluid() {
            return this.layoutConfig("content.width") === "fluid";
        },
    },
};
</script>
